const LocalStorageService = (function () {
	let _service;

	/**
	 * Get service instance
	 *
	 * @returns {_getService|*}
	 * @private
	 */
	function _getService() {
		if (!_service) {
			_service = this;
			return _service
		}
		return _service
	}

	/**
	 * Store a token & expiry time
	 *
	 * @param tokenObj
	 * @private
	 */
	function _setToken(tokenObj) {
		localStorage.setItem('@auth:accessToken', tokenObj.auth_token);

		const tokenExpiresAt = new Date();
		tokenExpiresAt.setTime(tokenExpiresAt.getTime() + (tokenObj.expires_in * 1000));
		localStorage.setItem('@auth:tokenExpiresAt', tokenExpiresAt.toJSON());
	}

	/**
	 * Get the access token
	 *
	 * @returns {string}
	 * @private
	 */
	function _getAccessToken() {
		return localStorage.getItem('@auth:accessToken');
	}

	/**
	 * Get the expiry time
	 *
	 * @returns {null|Date}
	 * @private
	 */
	function _getTokenExpiresAt() {
		const expAt = localStorage.getItem('@auth:tokenExpiresAt');
		if (expAt !== null) {
			return new Date(Date.parse(expAt));
		}
		return null;
	}

	/**
	 * Check whether the token is expired
	 *
	 * @returns {boolean}
	 * @private
	 */
	function _isTokenValid() {
		const expiry = _getTokenExpiresAt();
		if (expiry) {
			return Date.now() <= expiry.getTime();
		}
		return false
	}

	/**
	 * Delete token & expiry from storage
	 *
	 * @private
	 */
	function _clearToken() {
		localStorage.removeItem('@auth:accessToken');
		localStorage.removeItem('@auth:tokenExpiresAt');
	}

	/**
	 * Acquires all filters of the given namespace
	 *
	 * @param namespace
	 * @returns {{presets: Array, selected: null}|{presets: (*[]|*[]), selected: null}}
	 */
	function _fetchFilters (namespace) {
		const storage = JSON.parse(localStorage.getItem(namespace));
		if (storage && storage.filterPresets) {
			return { presets: storage.filterPresets, selected: null };
		}
		return { presets: [], selected: null };
	}

	/**
	 * Saves a filter of the given namespace to the local storage
	 *
	 * @param namespace
	 * @param item
	 * @param selectedKey
	 * @returns {{presets: (*[]|*[]), selected: number}}
	 */
	function _saveFilter (namespace, item, selectedKey = null) {
		let storage = JSON.parse(localStorage.getItem(namespace));

		if (storage) {
			if (storage.filterPresets) {
				if (selectedKey !== null) {
					/** A pre-existing item is currently selected, overwrite it */
					storage.filterPresets[selectedKey] = item;
				} else {
					/** The user has typed something into the filter input, save it as a new item */
					storage.filterPresets.push(item);
				}
			} else {
				/** AdWords filter presets are empty */
				storage.filterPresets = [item];
			}

			localStorage.setItem(namespace, JSON.stringify(storage));
		} else {
			/** AdWords storage is empty */
			storage = { filterPresets: [item] };
			localStorage.setItem(namespace, JSON.stringify(storage));
		}

		return {
			selected: selectedKey === null ? storage.filterPresets.length - 1 : selectedKey,
			presets: storage.filterPresets,
		};
	}

	/**
	 * Deletes a filter of the given namespace from the local storage
	 *
	 * @param namespace
	 * @param key
	 * @returns {{presets: (*[]|*[]), selected: null}}
	 */
	function _deleteFilter (namespace, key = null) {
		const storage = JSON.parse(localStorage.getItem(namespace));
		if (key !== null && storage.filterPresets && storage.filterPresets.length) {
			storage.filterPresets.splice(key, 1);
			localStorage.setItem(namespace, JSON.stringify(storage));
		}

		return {
			selected: null,
			presets: storage.filterPresets,
		};
	}

	return {
		isTokenValid: _isTokenValid,
		deleteFilter: _deleteFilter,
		fetchFilters: _fetchFilters,
		saveFilter: _saveFilter,
		getService: _getService,
		setToken: _setToken,
		getAccessToken: _getAccessToken,
		getTokenExpiresAt: _getTokenExpiresAt,
		clearToken: _clearToken
	}
})();
export default LocalStorageService;