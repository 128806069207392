import ReactDOM from "react-dom";

export const measureVisibleElement = element => {
  const DOMNode = ReactDOM.findDOMNode(element);
  const rect = DOMNode.getBoundingClientRect();
  return {
    top: rect.top,
    left: rect.left,
    width: rect.width,
    height: rect.height,
  };
};

/**
 * Downloads a Blob file on the client side
 *
 * @param data
 */
export function downloadFile (data) {
  const url = window.URL.createObjectURL(data.file);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', data.name);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}

/**
 * Detect if an adblocker is installed
 *
 * @returns {boolean}
 */
export function adBlockCheck() {
  return document.getElementById('ab-detector') === null;
}