import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { logger as loggerMiddleware } from 'redux-logger';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';

// creates the store
export default (rootReducer, rootSaga, history) => {
  /* ------------- Redux Configuration ------------- */

  const middleware = (process.env.NODE_ENV !== 'production') ? [loggerMiddleware] : [];
  const enhancers = [];

  /* ------------- Connected React Router ------------- */

  const routerMiddleware = createRouterMiddleware(history);
  middleware.push(routerMiddleware);

  /* ------------- Saga Middleware ------------- */

  const sagaMonitor = null;
  const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
  middleware.push(sagaMiddleware);

  /* ------------- Assemble Middleware ------------- */

  enhancers.push(applyMiddleware(...middleware));

  const createAppropriateStore = createStore;
  const store = createAppropriateStore(rootReducer, compose(...enhancers));

  // kick off root saga
  let sagasManager = sagaMiddleware.run(rootSaga);

  return {
    store,
    sagasManager,
    sagaMiddleware,
  };
};