import { call, put, select } from 'redux-saga/effects';
import AdWordsActions, { AdWordsSelectors } from '../reducers/AdWordsRedux';
import { response_routine_check, stringToArrayBuffer } from '../helpers/XhrHelper';

function * adWordsListRequest(api) {
	const response = yield call(api.listAccounts);

	const responseIsValid = response_routine_check(response);

	if (responseIsValid !== true) {
		return yield put(AdWordsActions.adWordsFailure(responseIsValid));
	}

	yield put(AdWordsActions.adWordsListSuccess(response.data));
}

function * adWordsListSuccess({list}) {
}

function * adWordsSaveRequest(api, {item}) {
	const response = yield call(api.saveAccount, item);

	const responseIsValid = response_routine_check(response);

	if (responseIsValid !== true) {
		return yield put(AdWordsActions.adWordsFailure(responseIsValid));
	}

	yield put(AdWordsActions.adWordsSaveSuccess(response.data[0]));
}

function * adWordsSaveSuccess({item}) {
	let list = yield select(AdWordsSelectors.list);

	if (list) {
		let exists = false;
		list = list.map(value => {
			if (value.id === item.id) {
				exists = true;
				return item;
			}
			return value;
		});
		if (!exists) {
			list = [...list, item];
		}

		yield put(AdWordsActions.adWordsListSuccess(list));
	} else {
		yield put(AdWordsActions.adWordsListSuccess([item]));
	}
}

function * adWordsReportRequest(api, {params}) {
	const response = yield call(api.createReport, params);

	const responseIsValid = response_routine_check(response);

	if (responseIsValid !== true) {
		return yield put(AdWordsActions.adWordsFailure(responseIsValid));
	}

	if (response.data.status === 'DONE') {
		yield put(AdWordsActions.adWordsReportSuccess({
			name: response.data.name,
			status: response.data.status,
			file: new Blob([stringToArrayBuffer(atob(response.data.file))], {type: response.data.type}),
		}));
	} else {
		yield put(AdWordsActions.adWordsReportSuccess({
			name: '',
			status: response.data.status,
			file: null,
		}));
	}
}

function * adWordsReportSuccess({report}) {
}

function * adWordsFailure({error}) {
}

function * adWordsDeleteRequest(api, {ids}) {
	const response = yield call(api.deleteAccount, ids);

	const responseIsValid = response_routine_check(response);

	if (responseIsValid !== true) {
		return yield put(AdWordsActions.adWordsFailure(responseIsValid));
	}
	const list = yield select(AdWordsSelectors.list);
	yield put(AdWordsActions.adWordsDeleteSuccess(list.filter(item => ids.indexOf(item.id) === -1)));
}

function * adWordsDeleteSuccess({list}) {
}

export {
	adWordsListRequest,
	adWordsListSuccess,
	adWordsFailure,
	adWordsSaveRequest,
	adWordsSaveSuccess,
	adWordsDeleteRequest,
	adWordsDeleteSuccess,
	adWordsReportRequest,
	adWordsReportSuccess,
};