import { call, put, select } from 'redux-saga/effects';
import InvoiceActions, { InvoiceSelectors } from '../reducers/InvoiceRedux';

function * invoiceListRequest(api) {
	const response = yield call(api.listInvoices);

	if (!response.ok) {
		return yield put(InvoiceActions.invoiceFailure(response.data.error));
	}

	yield put(InvoiceActions.invoiceListSuccess(response.data));
}

function * invoiceListSuccess({list}) {
}

function * invoiceFailure({error}) {
}

function * invoiceSaveRequest(api, {item}) {
	const response = yield call(api.saveInvoice, item);

	if (!response.ok) {
		return yield put(InvoiceActions.invoiceFailure(response.data.error));
	}

	yield put(InvoiceActions.invoiceSaveSuccess(response.data[0]));
}

function * invoiceSaveSuccess({item}) {
	let list = yield select(InvoiceSelectors.list);
	if (list) {
		let exists = false;
		list = list.map(value => {
			if (value.id === item.id) {
				exists = true;
				return item;
			}
			return value;
		});
		if (!exists) {
			list = [...list, item];
		}

		yield put(InvoiceActions.invoiceListSuccess(list));
	} else {
		yield put(InvoiceActions.invoiceListSuccess([item]));
	}
}

function * invoiceDeleteRequest(api, {ids}) {
	const response = yield call(api.deleteInvoice, ids);

	if (!response.ok) {
		return yield put(InvoiceActions.invoiceFailure(response.data.error));
	}
	const list = yield select(InvoiceSelectors.list);
	yield put(InvoiceActions.invoiceDeleteSuccess(list.filter(item => ids.indexOf(item.id) === -1)));
}

function * invoiceDeleteSuccess({list}) {
}

export {
	invoiceListRequest,
	invoiceListSuccess,
	invoiceFailure,
	invoiceSaveRequest,
	invoiceSaveSuccess,
	invoiceDeleteRequest,
	invoiceDeleteSuccess,
};