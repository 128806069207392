import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  adWordsListRequest: null,
  adWordsListSuccess: ['list'],
  adWordsFailure: ['error'],
  adWordsSaveRequest: ['item'],
  adWordsSaveSuccess: ['item'],
  adWordsDeleteRequest: ['ids'],
  adWordsDeleteSuccess: ['list'],
  adWordsReportRequest: ['params'],
  adWordsReportSuccess: ['report'],
});

export const AdWordsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: null,
  report: null,
  error: null,
  fetching: false,
});

/* ------------- Selectors ------------- */

export const AdWordsSelectors = {
  list: (state) => state.adWords.list,
};

/* ------------- Reducers ------------- */

// Attempt to get account list
export const adWordsListRequest = (state) => state.merge({ fetching: true });

// Successfully got account list
export const adWordsListSuccess = (state, { list }) => state.merge({ fetching: false, error: null, list });

// We've had a problem
export const adWordsFailure = (state, { error }) => state.merge({ fetching: false, error });

// Attempt to save an account's data
export const adWordsSaveRequest = (state) => state.merge({ fetching: true });

// Successfully deleted account
export const adWordsSaveSuccess = (state) => state.merge({ fetching: true, error: null });

// Attempt to delete an account
export const adWordsDeleteRequest = (state) => state.merge({ fetching: true });

// Successfully deleted account
export const adWordsDeleteSuccess = (state, { list }) => state.merge({ fetching: false, error: null, list });

// Attempt to create one or more reports
export const adWordsReportRequest = (state) => state.merge({ fetching: true, report: null });

// Successfully created report(s)
export const adWordsReportSuccess = (state, { report }) => state.merge({ fetching: false, error: null, report });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.AD_WORDS_LIST_REQUEST]: adWordsListRequest,
  [Types.AD_WORDS_LIST_SUCCESS]: adWordsListSuccess,
  [Types.AD_WORDS_FAILURE]: adWordsFailure,
  [Types.AD_WORDS_SAVE_REQUEST]: adWordsSaveRequest,
  [Types.AD_WORDS_SAVE_SUCCESS]: adWordsSaveSuccess,
  [Types.AD_WORDS_DELETE_REQUEST]: adWordsDeleteRequest,
  [Types.AD_WORDS_DELETE_SUCCESS]: adWordsDeleteSuccess,
  [Types.AD_WORDS_REPORT_REQUEST]: adWordsReportRequest,
  [Types.AD_WORDS_REPORT_SUCCESS]: adWordsReportSuccess,
});
