import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
	reportListRequest: ['page'],
	reportListSuccess: ['list'],
	reportFailure: ['error'],
	reportDeleteRequest: ['ids'],
	reportDeleteSuccess: ['list'],
	reportDownloadRequest: ['ids'],
	reportDownloadSuccess: ['fileData'],
	reportAccount: null,
	reportClearFileRequest: null
});

export const ReportTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	list: null,
	fileData: null,
	error: null,
	fetching: false,
});

/* ------------- Selectors ------------- */

export const ReportSelectors = {
	list: (state) => state.reports.list,
};

/* ------------- Reducers ------------- */

// Attempt to get report list
export const reportListRequest = (state) => state.merge({fetching: true});

// Successfully got report list
export const reportListSuccess = (state, {list}) => state.merge({fetching: false, error: null, list});

// We've had a problem
export const reportFailure = (state, {error}) => state.merge({fetching: false, error});

// Attempt to delete one or more reports
export const reportDeleteRequest = (state) => state.merge({fetching: true});

// Successfully deleted report(s)
export const reportDeleteSuccess = (state, {list}) => state.merge({fetching: false, error: null, list});

// Attempt to download a report
export const reportDownloadRequest = (state) => state.merge({fetching: true, report: null});

// Successfully downloaded report
export const reportDownloadSuccess = (state, {fileData}) => state.merge({fetching: false, error: null, fileData});

// Clear file after download
export const reportClearFileRequest = (state) => state.merge({fileData: null});

//
export const reportAccount = (state) => state.merge({fetching: false});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.REPORT_LIST_REQUEST]: reportListRequest,
	[Types.REPORT_LIST_SUCCESS]: reportListSuccess,
	[Types.REPORT_FAILURE]: reportFailure,
	[Types.REPORT_DELETE_REQUEST]: reportDeleteRequest,
	[Types.REPORT_DELETE_SUCCESS]: reportDeleteSuccess,
	[Types.REPORT_DOWNLOAD_REQUEST]: reportDownloadRequest,
	[Types.REPORT_DOWNLOAD_SUCCESS]: reportDownloadSuccess,
	[Types.REPORT_CLEAR_FILE_REQUEST]: reportClearFileRequest,
	[Types.REPORT_ACCOUNT]: reportAccount,
});
