import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
	invoiceListRequest: null,
	invoiceListSuccess: ['list'],
	invoiceFailure: ['error'],
	invoiceSaveRequest: ['item'],
	invoiceSaveSuccess: ['item'],
	invoiceDeleteRequest: ['ids'],
	invoiceDeleteSuccess: ['list'],
});

export const InvoiceTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	list: null,
	error: null,
	fetching: false,
});

/* ------------- Selectors ------------- */

export const InvoiceSelectors = {
	list: (state) => state.companies.list,
};

/* ------------- Reducers ------------- */

// Attempt to get invoice list
export const invoiceListRequest = (state) => state.merge({fetching: true});

// Successfully got invoice list
export const invoiceListSuccess = (state, {list}) => state.merge({fetching: false, error: null, list});

// We've had a problem
export const invoiceFailure = (state, {error}) => state.merge({fetching: false, error});

// Attempt to create or edit a invoice
export const invoiceSaveRequest = (state) => state.merge({fetching: true});

// Successfully created or edited a invoice
export const invoiceSaveSuccess = (state) => state.merge({fetching: true, error: null});

// Attempt to delete a invoice
export const invoiceDeleteRequest = (state) => state.merge({fetching: true});

// Successfully deleted a invoice
export const invoiceDeleteSuccess = (state, {list}) => state.merge({fetching: false, error: null, list});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.INVOICE_LIST_REQUEST]: invoiceListRequest,
	[Types.INVOICE_LIST_SUCCESS]: invoiceListSuccess,
	[Types.INVOICE_FAILURE]: invoiceFailure,
	[Types.INVOICE_SAVE_REQUEST]: invoiceSaveRequest,
	[Types.INVOICE_SAVE_SUCCESS]: invoiceSaveSuccess,
	[Types.INVOICE_DELETE_REQUEST]: invoiceDeleteRequest,
	[Types.INVOICE_DELETE_SUCCESS]: invoiceDeleteSuccess,
});
