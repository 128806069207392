export default {
	API_BASE_URL: '/api',
	LOGIN_PATH: '/auth/login',
	REFRESH_TOKEN_PATH: '/auth/refresh',
	APP_NAME: 'SRG Reporting Tool',
	DEBUG: process.env.NODE_ENV !== 'production',
	commonCountries: [
		'HU', 'AT', 'DE', 'AE', 'AU', 'BE', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'GR',
		'IS', 'IT', 'LV', 'LT', 'LU', 'NL', 'NO', 'PL', 'PT', 'RO', 'RU', 'RS', 'SK', 'SI',
		'ES', 'SE', 'CH', 'TR', 'UA', 'GB',
	],
	commonCurrencies: ['EUR', 'USD', 'HUF', 'PLN', 'SEK'],
	commonLanguages: ['en', 'de', 'hu'],
	commonTimeZones: ['Europe/Budapest', 'Europe/Vienna'],
	defaultVatType: 'hu_normal',
}