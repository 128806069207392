import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
	loginRequest: ['email', 'password'],
	loginRequestWithToken: ['token'],
	refreshToken: null,
	loginSuccess: ['data'],
	userData: null,
	logoutRequest: null,
	logoutSuccess: null,
	loginFailure: ['error'],
});

export const AuthTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	data: null,
	error: null,
	fetching: false,
});

/* ------------- Selectors ------------- */

export const AuthSelectors = {
	isLoggedIn: (state) => !!state.auth.data && !!state.auth.data.auth_token,
	hasUserData: (state) => !!(state.auth.data && state.auth.data.roles)
};

/* ------------- Reducers ------------- */

// Attempt to login
export const loginRequest = (state) => state.merge({fetching: true});

// Attempt to login (get user data) with token
export const loginRequestWithToken = (state) => state.merge({fetching: true});

// Attempt to refresh the token
export const refreshToken = (state) => state.merge({fetching: true});

// Successfully logged in
export const loginSuccess = (state, {data}) => state.merge({fetching: false, error: null, data: {...state.data, ...data}});

// Get used data
export const userData = (state) => state.merge({fetching: true});

// Attempt to log out
export const logoutRequest = (state) => state.merge({fetching: true});

// Successfully logged out
export const logoutSuccess = (state) => INITIAL_STATE;

// We've had a problem
export const loginFailure = (state, {error}) => state.merge({fetching: false, error});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.LOGIN_REQUEST]: loginRequest,
	[Types.LOGIN_REQUEST_WITH_TOKEN]: loginRequestWithToken,
	[Types.REFRESH_TOKEN]: refreshToken,
	[Types.LOGIN_SUCCESS]: loginSuccess,
	[Types.USER_DATA]: userData,
	[Types.LOGOUT_REQUEST]: logoutRequest,
	[Types.LOGOUT_SUCCESS]: logoutSuccess,
	[Types.LOGIN_FAILURE]: loginFailure,
});
