import { call, put, select, race } from 'redux-saga/effects';
import ReportActions, { ReportSelectors } from '../reducers/ReportRedux';
import AdWordsActions, { AdWordsSelectors } from '../reducers/AdWordsRedux';
import { response_routine_check, stringToArrayBuffer } from '../helpers/XhrHelper';

function * reportListRequest(api, {page}) {
	const response = yield call(api.listReports, page);

	const responseIsValid = response_routine_check(response);

	if (responseIsValid !== true) {
		return yield put(ReportActions.reportFailure(responseIsValid));
	}

	yield put(ReportActions.reportListSuccess(response.data));
}

function * reportListSuccess({list}) {
}

function * reportDownloadRequest(api, {ids}) {
	const response = yield call(api.downloadReport, ids);

	const responseIsValid = response_routine_check(response);

	if (responseIsValid !== true) {
		return yield put(ReportActions.reportFailure(responseIsValid));
	}

	yield put(ReportActions.reportDownloadSuccess({
		name: response.data.name,
		status: response.data.status,
		file: new Blob([stringToArrayBuffer(atob(response.data.file))], {type: response.data.type}),
	}));
}

function * reportDownloadSuccess({fileData}) {
}

function * reportClearFileRequest() {
}

function * reportAccounts() {
	const accounts = yield select(AdWordsSelectors.list);

	if (!accounts) {
		yield put(AdWordsActions.adWordsListRequest());

		const {list, error} = yield race({
			list: call(AdWordsActions.adWordsListSuccess),
			error: call(AdWordsActions.adWordsFailure),
		});

		if (error) {
			return yield put(ReportActions.reportFailure(error));
		}
	}
}

function * reportFailure({error}) {
}

function * reportDeleteRequest(api, {ids}) {
	const response = yield call(api.deleteReport, ids);

	const responseIsValid = response_routine_check(response);

	if (responseIsValid !== true) {
		return yield put(ReportActions.reportFailure(responseIsValid));
	}
	const list = yield select(ReportSelectors.list);
	yield put(ReportActions.reportDeleteSuccess(list.filter(item => ids.indexOf(item.id) === -1)));
}

function * reportDeleteSuccess({list}) {
}

export {
	reportListRequest,
	reportListSuccess,
	reportDownloadRequest,
	reportDownloadSuccess,
	reportClearFileRequest,
	reportFailure,
	reportDeleteRequest,
	reportDeleteSuccess,
	reportAccounts
};