import appConfig from '../config/AppConfig';
import { create } from 'apisauce';
import { push } from 'connected-react-router';
import i18n from '../i18n';
import LocalStorageService from './LocalStorage';
const localStorageService = LocalStorageService.getService();

export default () => {
	const api = create({
		// base URL is read from the "constructor"
		baseURL: appConfig.API_BASE_URL,
		// here are some default headers
		headers: {
			'Accept-Language': i18n.language,
			'Content-Type': 'application/json',
			'X-Delegated-Application': appConfig.APP_NAME,
		},
		// 1000 second timeout...
		timeout: 1000000,
	});

	const setAuthorization = (token) => token ? api.setHeader('Authorization', 'Bearer ' + token) : api.deleteHeader('Authorization');
	const clearAuthorization = () => delete api.headers['Authorization'];
	const refresh = () => api.post(appConfig.REFRESH_TOKEN_PATH);

	const localToken = localStorageService.getAccessToken();
	if (localToken && localToken !== 'undefined') {
		setAuthorization(localToken);
	}

	api.addAsyncResponseTransform(async (response) => {
		switch (response.status) {
			case 200:
			case 201:
			case 202:
			case 204:
				if (response.data && response.data.error) {
					response.ok = false;
				}
				break;
			case 301:
			case 302:
				response.ok = false;
				response.data.error = 'redirected';
				break;
			case 400:
				response.data.error = response.data.error || 'badRequest';
				break;
			case 401:
				// We don't have to take care of the refresh token route, since it's called from here
				if (response.config.url !== appConfig.API_BASE_URL + appConfig.REFRESH_TOKEN_PATH) {
					// Get the invalid token
					const token = localStorageService.getAccessToken();
					if (token !== null) {
						// Attempt to refresh the token
						const refreshResponse = await refresh();
						if (refreshResponse.ok) {
							// Store new token
							localStorageService.setToken(refreshResponse.data);
							setAuthorization(localStorageService.getAccessToken());
							response.config.headers.Authorization = `Bearer ${refreshResponse.data.auth_token}`;

							// Call the last failed endpoint again
							const repeatedResponse = await api.axiosInstance.request(response.config);
							// Set up the new data
							response.ok = repeatedResponse.ok || true;
							response.status = repeatedResponse.status;
							response.data = repeatedResponse.data;
						} else if (response.config.url !== appConfig.API_BASE_URL + appConfig.LOGIN_PATH) {
							clearAuthorization();
							push('/login');
						}
					} else if (response.config.url !== appConfig.API_BASE_URL + appConfig.LOGIN_PATH) {
						push('/login');
					}
				}
				break;
			case 403:
				response.data.error = response.data.error || 'unauthorized';
				break;
			case 404:
				response.data.error = response.data.error || 'notFound';
				break;
			case 405:
				response.data.error = response.data.error || 'methodNotAllowed';
				break;
			case 406:
				response.data.error = response.data.error || 'notAcceptable';
				break;
			case 500:
				response.data.error = response.data.error || 'internalServerError';
				break;
			default:
				response.data.error = response.data.error || 'unknownError';
				break;
		}
	});

	return {
		headers: api.headers,

		clearAuthorization: clearAuthorization,
		setAuthorization: setAuthorization,
		setAcceptLanguage: (lang) => lang ? api.setHeader('Accept-Language', lang) : api.deleteHeader('Accept-Language'),

		// Auth actions
		login: ({email, password}) => api.post(appConfig.LOGIN_PATH, {email, password}),
		refresh: refresh,
		userData: () => api.get('/auth/user'),
		logout: () => api.delete('/auth/logout'),

		// Company actions
		listCompanies: () => api.get('/company/list'),
		saveCompany: (params) => api.post('/company/save', params),
		changeVat: (params) => api.post('/company/change_vat', params),
		assignAccounts: (params) => api.post('/company/assign', params),
		deleteCompany: (ids) => api.delete('/company/delete', {ids: ids}),

		// Invoice actions
		listInvoices: () => api.get('/invoice/list'),
		saveInvoice: (params) => api.post('/invoice/save', params),
		deleteInvoice: (ids) => api.delete('/invoice/delete', {ids: ids}),

		// Utilities
		listPaymentTypes: () => api.get('/utils/payment_types'),
		listVatTypes: () => api.get('/utils/vat_types'),
		listUnitTypes: () => api.get('/utils/unit_types'),

		// AdWords actions
		listAccounts: () => api.get('/adwords/list'),
		saveAccount: (params) => api.post('/adwords/save', params),
		deleteAccount: (ids) => api.delete('/adwords/delete', {ids: ids}),
		createReport: (params) => api.post('/adwords/report', params),

		// Report actions
		listReports: () => api.get('/report/list'),
		downloadReport: (ids) => api.post('/report/download', {ids: ids}),
		deleteReport: (ids) => api.delete('/report/delete', {ids: ids}),
	};
}