import { call, put, select } from 'redux-saga/effects';
import UtilityActions, { UtilitySelectors } from '../reducers/UtilityRedux';

function * paymentListRequest(api) {
	const paymentTypes = yield select(UtilitySelectors.paymentTypes);

	if (paymentTypes) {
		return yield put(UtilityActions.paymentListSuccess(paymentTypes));
	}

	const response = yield call(api.listPaymentTypes);
	if (!response.ok) {
		return yield put(UtilityActions.utilityFailure(response.data.error));
	}

	yield put(UtilityActions.paymentListSuccess(response.data));
}

function * paymentListSuccess({vatTypes}) {
}

function * vatListRequest(api) {
	const vatTypes = yield select(UtilitySelectors.vatTypes);

	if (vatTypes) {
		return yield put(UtilityActions.vatListSuccess(vatTypes));
	}

	const response = yield call(api.listVatTypes);
	if (!response.ok) {
		return yield put(UtilityActions.utilityFailure(response.data.error));
	}

	response.data.map(item => item.multiplier = Number.parseFloat(item.multiplier));

	yield put(UtilityActions.vatListSuccess(response.data));
}

function * vatListSuccess({vatTypes}) {
}

function * unitListRequest(api) {
	const unitTypes = yield select(UtilitySelectors.unitTypes);

	if (unitTypes) {
		return yield put(UtilityActions.unitListSuccess(unitTypes));
	}

	const response = yield call(api.listUnitTypes);
	if (!response.ok) {
		return yield put(UtilityActions.utilityFailure(response.data.error));
	}

	yield put(UtilityActions.unitListSuccess(response.data));
}

function * unitListSuccess({unitTypes}) {
}

function * utilityFailure({error}) {
}


export {
	paymentListRequest,
	paymentListSuccess,
	vatListRequest,
	vatListSuccess,
	unitListRequest,
	unitListSuccess,
	utilityFailure
};