import { put } from 'redux-saga/effects';
import NotificationActions from '../reducers/NotificationRedux';
import NotificationBar from '../components/NotificationBar';


function * setNotification({message, notificationType}) {
	if (!!message) {
		return yield put(NotificationActions.notificationSuccess(message, notificationType || NotificationBar.ERROR));
	}
}

function * notificationSuccess() {
}

export {
	setNotification,
	notificationSuccess
}