import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
	paymentListRequest: null,
	paymentListSuccess: ['paymentTypes'],
	vatListRequest: null,
	vatListSuccess: ['vatTypes'],
	unitListRequest: null,
	unitListSuccess: ['unitTypes'],
	utilityFailure: ['error']
});

export const UtilityTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	paymentTypes: null,
	vatTypes: null,
	unitTypes: null,
	error: null,
	fetching: false,
});

/* ------------- Selectors ------------- */

export const UtilitySelectors = {
	paymentTypes: (state) => state.utilities.paymentTypes,
	vatTypes: (state) => state.utilities.vatTypes,
	unitTypes: (state) => state.utilities.unitTypes,
};

/* ------------- Reducers ------------- */

// Attempt to get vat types list
export const paymentListRequest = (state) => state.merge({fetching: true});

// Successfully got vat types list
export const paymentListSuccess = (state, {paymentTypes}) => state.merge({fetching: false, error: null, paymentTypes});

// Attempt to get vat types list
export const vatListRequest = (state) => state.merge({fetching: true});

// Successfully got vat types list
export const vatListSuccess = (state, {vatTypes}) => state.merge({fetching: false, error: null, vatTypes});

// Attempt to get vat types list
export const unitListRequest = (state) => state.merge({fetching: true});

// Successfully got vat types list
export const unitListSuccess = (state, {unitTypes}) => state.merge({fetching: false, error: null, unitTypes});

// We've had a problem
export const utilityFailure = (state, {error}) => state.merge({fetching: false, error});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.PAYMENT_LIST_REQUEST]: paymentListRequest,
	[Types.PAYMENT_LIST_SUCCESS]: paymentListSuccess,
	[Types.VAT_LIST_REQUEST]: vatListRequest,
	[Types.VAT_LIST_SUCCESS]: vatListSuccess,
	[Types.UNIT_LIST_REQUEST]: unitListRequest,
	[Types.UNIT_LIST_SUCCESS]: unitListSuccess,
	[Types.UTILITY_FAILURE]: utilityFailure,
});
