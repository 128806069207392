import { push } from 'connected-react-router';

export const RESPONSE_CODES = {
  200: 'OK',
  400: 'BAD_REQUEST',
  401: 'UNAUTHORIZED',
  403: 'FORBIDDEN',
  404: 'NOT_FOUND',
  500: 'INTERNAL_SERVER_ERROR',
  unknown: 'UNKNOWN_ERROR',
};

export function value_check (data, dataCheck = {}) {
  const error = {
    invalid: [],
  };

  Object.keys(dataCheck).forEach((key) => {
    const DATA_CHECK_ERROR_CODE = dataCheck[key];

    if (!data || data[key] === undefined || (typeof data[key] === 'string' && !data[key].length)) error.invalid.push({
      name: key,
      reason: DATA_CHECK_ERROR_CODE,
    });
  });

  if (error.invalid.length) return error;

  return true;
}

export function response_routine_check (response, dataCheck = {}) {
  const { ok, status } = response;

  const error = { status };

  // Whether the request returned with a 200 status code at all
  if (!ok) {
    console.log('XHR error, status code:', status, response);

    if (parseInt(status) === 401) {
      push('/login');
    }

    error.reason = (response.data && response.data.error) || RESPONSE_CODES[Object.keys(RESPONSE_CODES).indexOf(status + '') !== -1 ? status : 'unknown'];

    return error;
  }

  // Routine-checking the given data-properties
  const valuesOK = value_check(response.data, dataCheck);

  if (valuesOK !== true) {
    return error;
  }

  return true;
}

export function stringToArrayBuffer (data) {
  const buffer = new ArrayBuffer(data.length);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < data.length; ++i) view[i] = data.charCodeAt(i) & 0xFF;
  return buffer;
}