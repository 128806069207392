import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from './CloseIcon';
import { Translation } from 'react-i18next';


export default class NotificationBar extends React.Component {
	static propTypes = {
		message: PropTypes.string,
		type: PropTypes.string,
		onClose: PropTypes.func,
		needsReload: PropTypes.bool
	};

	static defaultProps = {
		message: '',
		type: 'error',
		onClose: () => {
		},
		needsReload: false
	};

	static get FATAL() {
		return 'fatal';
	}

	static get ERROR() {
		return 'error';
	}

	static get WARNING() {
		return 'warning';
	}

	static get SUCCESS() {
		return 'success';
	}

	constructor(props) {
		super(props);

		this.state = {
			open: false,
		};
	}

	close = e => {
		if (e) e.preventDefault();

		this.setState({
			open: false,
		});

		this.props.onClose();
	};

	open = (autoHide = false) => {
		this.setState({
			open: true,
		});

		if (autoHide) {
			setTimeout(() => this.close(), 5000);
		}
	};

	renderReloadLink() {
		return this.props.needsReload ? (
			<Translation>{t => (<a className={'refresh-link'} href={'#'} onClick={() => window.location.reload()}>{t('common.refreshPage')}</a>)}</Translation>
		) : '';
	}

	render() {
		return (
			<div className={`notificationBar ${this.state.open ? 'open' : 'closed'} ${this.props.type}`}>
				<div className="message">
					{this.props.message ? (this.props.message) : (this.props.children)}
				</div>
				{this.renderReloadLink()}
				<CloseIcon onClose={this.close}/>
			</div>
		);
	}
}
