import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
	companyListRequest: null,
	companyListSuccess: ['list'],
	companyFailure: ['error'],
	companySaveRequest: ['item'],
	companySaveSuccess: ['item'],
	companyChangeVatRequest: ['id', 'vatId'],
	companyAssignAccountsRequest: ['id', 'accountIds'],
	companyDeleteRequest: ['ids'],
	companyDeleteSuccess: ['list'],
});

export const CompanyTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	list: null,
	error: null,
	fetching: false,
});

/* ------------- Selectors ------------- */

export const CompanySelectors = {
	list: (state) => state.companies.list,
};

/* ------------- Reducers ------------- */

// Attempt to get company list
export const companyListRequest = (state) => state.merge({fetching: true});

// Successfully got company list
export const companyListSuccess = (state, {list}) => state.merge({fetching: false, error: null, list});

// We've had a problem
export const companyFailure = (state, {error}) => state.merge({fetching: false, error});

// Attempt to create or edit a company
export const companySaveRequest = (state) => state.merge({fetching: true});

// Successfully created or edited a company
export const companySaveSuccess = (state) => state.merge({fetching: true, error: null});

// Attempt to change default VAT type
export const companyChangeVatRequest = (state) => state.merge({fetching: true});

// Attempt to associate account with the given company
export const companyAssignAccountsRequest = (state) => state.merge({fetching: true});

// Attempt to delete a company
export const companyDeleteRequest = (state) => state.merge({fetching: true});

// Successfully deleted a company
export const companyDeleteSuccess = (state, {list}) => state.merge({fetching: false, error: null, list});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.COMPANY_LIST_REQUEST]: companyListRequest,
	[Types.COMPANY_LIST_SUCCESS]: companyListSuccess,
	[Types.COMPANY_FAILURE]: companyFailure,
	[Types.COMPANY_SAVE_REQUEST]: companySaveRequest,
	[Types.COMPANY_SAVE_SUCCESS]: companySaveSuccess,
	[Types.COMPANY_CHANGE_VAT_REQUEST]: companyChangeVatRequest,
	[Types.COMPANY_ASSIGN_ACCOUNTS_REQUEST]: companyAssignAccountsRequest,
	[Types.COMPANY_DELETE_REQUEST]: companyDeleteRequest,
	[Types.COMPANY_DELETE_SUCCESS]: companyDeleteSuccess,
});
