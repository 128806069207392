import { takeLatest, all } from 'redux-saga/effects';
import bundleActions from '../services/Api';

/* ------------- Types ------------- */

import { AuthTypes } from '../reducers/AuthRedux';
import { NotificationTypes } from '../reducers/NotificationRedux';
import { AdWordsTypes } from '../reducers/AdWordsRedux';
import { CompanyTypes } from '../reducers/CompanyRedux';
import { InvoiceTypes } from '../reducers/InvoiceRedux';
import { ReportTypes } from '../reducers/ReportRedux';
import { UtilityTypes } from '../reducers/UtilityRedux';

/* ------------- API actions ------------- */

const apiActions = bundleActions();

/* ------------- Sagas ------------- */

import * as AuthSagas from './AuthSagas';
import * as NotificationSagas from './NotificationSagas';
import * as AdWordsSagas from './AdWordsSagas';
import * as CompanySagas from './CompanySagas';
import * as InvoiceSagas from './InvoiceSagas';
import * as ReportSagas from './ReportSagas';
import * as UtilitySagas from './UtilitySagas';

/* ------------- Connect Types To Sagas ------------- */

export default function * root() {
	yield all([
		// Auth
		takeLatest(AuthTypes.LOGIN_REQUEST, AuthSagas.loginRequest, apiActions),
		takeLatest(AuthTypes.LOGIN_REQUEST_WITH_TOKEN, AuthSagas.loginRequestWithToken, apiActions),
		takeLatest(AuthTypes.REFRESH_TOKEN, AuthSagas.refreshToken, apiActions),
		takeLatest(AuthTypes.LOGIN_SUCCESS, AuthSagas.loginSuccess, apiActions),
		takeLatest(AuthTypes.USER_DATA, AuthSagas.userData, apiActions),
		takeLatest(AuthTypes.LOGOUT_REQUEST, AuthSagas.logoutRequest, apiActions),
		takeLatest(AuthTypes.LOGOUT_SUCCESS, AuthSagas.logoutSuccess, apiActions),
		takeLatest(AuthTypes.LOGIN_FAILURE, AuthSagas.loginFailure),
		// Notifications
		takeLatest(NotificationTypes.SET_NOTIFICATION, NotificationSagas.setNotification),
		takeLatest(NotificationTypes.NOTIFICATION_SUCCESS, NotificationSagas.notificationSuccess),
		// AdWords
		takeLatest(AdWordsTypes.AD_WORDS_LIST_REQUEST, AdWordsSagas.adWordsListRequest, apiActions),
		takeLatest(AdWordsTypes.AD_WORDS_LIST_SUCCESS, AdWordsSagas.adWordsListSuccess),
		takeLatest(AdWordsTypes.AD_WORDS_SAVE_REQUEST, AdWordsSagas.adWordsSaveRequest, apiActions),
		takeLatest(AdWordsTypes.AD_WORDS_SAVE_SUCCESS, AdWordsSagas.adWordsSaveSuccess),
		takeLatest(AdWordsTypes.AD_WORDS_REPORT_REQUEST, AdWordsSagas.adWordsReportRequest, apiActions),
		takeLatest(AdWordsTypes.AD_WORDS_REPORT_SUCCESS, AdWordsSagas.adWordsReportSuccess),
		takeLatest(AdWordsTypes.AD_WORDS_FAILURE, AdWordsSagas.adWordsFailure),
		takeLatest(AdWordsTypes.AD_WORDS_DELETE_REQUEST, AdWordsSagas.adWordsDeleteRequest, apiActions),
		takeLatest(AdWordsTypes.AD_WORDS_DELETE_SUCCESS, AdWordsSagas.adWordsDeleteSuccess),
		// Company
		takeLatest(CompanyTypes.COMPANY_LIST_REQUEST, CompanySagas.companyListRequest, apiActions),
		takeLatest(CompanyTypes.COMPANY_LIST_SUCCESS, CompanySagas.companyListSuccess),
		takeLatest(CompanyTypes.COMPANY_SAVE_REQUEST, CompanySagas.companySaveRequest, apiActions),
		takeLatest(CompanyTypes.COMPANY_SAVE_SUCCESS, CompanySagas.companySaveSuccess),
		takeLatest(CompanyTypes.COMPANY_CHANGE_VAT_REQUEST, CompanySagas.companyChangeVatRequest, apiActions),
		takeLatest(CompanyTypes.COMPANY_ASSIGN_ACCOUNTS_REQUEST, CompanySagas.companyAssignAccountsRequest, apiActions),
		takeLatest(CompanyTypes.COMPANY_FAILURE, CompanySagas.companyFailure),
		takeLatest(CompanyTypes.COMPANY_DELETE_REQUEST, CompanySagas.companyDeleteRequest, apiActions),
		takeLatest(CompanyTypes.COMPANY_DELETE_SUCCESS, CompanySagas.companyDeleteSuccess),
		// Invoices
		takeLatest(InvoiceTypes.INVOICE_LIST_REQUEST, InvoiceSagas.invoiceListRequest, apiActions),
		takeLatest(InvoiceTypes.INVOICE_LIST_SUCCESS, InvoiceSagas.invoiceListSuccess),
		takeLatest(InvoiceTypes.INVOICE_SAVE_REQUEST, InvoiceSagas.invoiceSaveRequest, apiActions),
		takeLatest(InvoiceTypes.INVOICE_SAVE_SUCCESS, InvoiceSagas.invoiceSaveSuccess),
		takeLatest(InvoiceTypes.INVOICE_FAILURE, InvoiceSagas.invoiceFailure),
		takeLatest(InvoiceTypes.INVOICE_DELETE_REQUEST, InvoiceSagas.invoiceDeleteRequest, apiActions),
		takeLatest(InvoiceTypes.INVOICE_DELETE_SUCCESS, InvoiceSagas.invoiceDeleteSuccess),
		// Report
		takeLatest(ReportTypes.REPORT_LIST_REQUEST, ReportSagas.reportListRequest, apiActions),
		takeLatest(ReportTypes.REPORT_LIST_SUCCESS, ReportSagas.reportListSuccess),
		takeLatest(ReportTypes.REPORT_DOWNLOAD_REQUEST, ReportSagas.reportDownloadRequest, apiActions),
		takeLatest(ReportTypes.REPORT_DOWNLOAD_SUCCESS, ReportSagas.reportDownloadSuccess),
		takeLatest(ReportTypes.REPORT_FAILURE, ReportSagas.reportFailure),
		takeLatest(ReportTypes.REPORT_DELETE_REQUEST, ReportSagas.reportDeleteRequest, apiActions),
		takeLatest(ReportTypes.REPORT_DELETE_SUCCESS, ReportSagas.reportDeleteSuccess),
		takeLatest(ReportTypes.REPORT_CLEAR_FILE_REQUEST, ReportSagas.reportClearFileRequest),
		takeLatest(ReportTypes.REPORT_ACCOUNT, ReportSagas.reportAccounts),
		// Utilities
		takeLatest(UtilityTypes.PAYMENT_LIST_REQUEST, UtilitySagas.paymentListRequest, apiActions),
		takeLatest(UtilityTypes.PAYMENT_LIST_SUCCESS, UtilitySagas.paymentListSuccess),
		takeLatest(UtilityTypes.VAT_LIST_REQUEST, UtilitySagas.vatListRequest, apiActions),
		takeLatest(UtilityTypes.VAT_LIST_SUCCESS, UtilitySagas.vatListSuccess),
		takeLatest(UtilityTypes.UNIT_LIST_REQUEST, UtilitySagas.unitListRequest, apiActions),
		takeLatest(UtilityTypes.UNIT_LIST_SUCCESS, UtilitySagas.unitListSuccess),
		takeLatest(UtilityTypes.UTILITY_FAILURE, UtilitySagas.utilityFailure),
	]);
}
