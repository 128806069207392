import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import NotificationBar from '../components/NotificationBar';

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
	setNotification: ['message', 'notificationType'],
	notificationSuccess: ['message', 'notificationType']
});

export const NotificationTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	currentMessage: null,
	lastMessage: null,
	notificationType: NotificationBar.ERROR,
	working: null
});

/* ------------- Selectors ------------- */

export const NotificationSelectors = {
	currentMessage: state => state.notification.currentMessage,
	lastMessage: state => state.notification.lastMessage,
	isWorking: state => state.notification.working
};

/* ------------- Reducers ------------- */

// Set a notification
export const setNotification = (state) => state.merge({working: true});

// Successfully set a notification
export const notificationSuccess = (state, {message, notificationType}) => state.merge({
	currentMessage: message,
	lastMessage: state.currentMessage,
	notificationType,
	working: false
});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.SET_NOTIFICATION]: setNotification,
	[Types.NOTIFICATION_SUCCESS]: notificationSuccess,
});
