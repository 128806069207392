import React, { Component, lazy } from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { adBlockCheck } from '../helpers/DOMHelper';
import { Admin, Manager, User } from '../services/Authorization';
import AuthActions from '../reducers/AuthRedux';
import NotificationActions from '../reducers/NotificationRedux';
import LocalStorageService from '../services/LocalStorage';
import { Translation } from 'react-i18next';
import NotificationBar from '../components/NotificationBar';

// Styles
//import '../../sass/app.scss'; //TODO enélkül első betöltésnél fura dolgokat csinál

// Layout
import Header from './Header';
import LoadOverlay from '../components/LoadOverlay';
import Footer from './Footer';

// Pages
const Home = lazy(() => import('./Home'));
const Login = lazy(() => import('./Login'));
const AdWords = lazy(() => import('./AdWords'));
const Reports = lazy(() => import('./Reports'));
const Companies = lazy(() => import('./Companies'));
const Invoices = lazy(() => import('./Invoices'));
const NotFound = lazy(() => import('./NotFound'));

// Storage
const localStorageService = LocalStorageService.getService();

class App extends Component {
	static propTypes = {
		auth: PropTypes.object,
		error: PropTypes.object,
		fetching: PropTypes.bool,
		incomingNotification: PropTypes.bool,
		notification: PropTypes.object,
		router: PropTypes.object,
		loginWithToken: PropTypes.func,
		logout: PropTypes.func,
		refreshToken: PropTypes.func,
		setNotification: PropTypes.func,
	};

	constructor(props) {
		super(props);

		this.state = {
			adBlockChecked: false,
			shouldOpenNotificationBar: false
		};

		if (!this.props.auth || !this.props.auth.roles) {
			this.props.getUserData();
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!this.state.adBlockChecked && adBlockCheck()) {
			setTimeout(() => {
				this.props.setNotification('adBlockNotice', NotificationBar.FATAL);
			}, 3000);

			this.setState({adBlockChecked: true})
		}

		if (this.props.notification && !this.props.incomingNotification && prevProps.incomingNotification) {
			this.setState({shouldOpenNotificationBar: true});
		}

		if (this.notificationBar && this.state.shouldOpenNotificationBar) {
			this.notificationBar.open();
			this.setState({shouldOpenNotificationBar: false});
		}
	}

	renderNotice() {
		return <NotificationBar
			needsReload={this.props.notification.notificationType === NotificationBar.FATAL}
			ref={r => this.notificationBar = r}
			type={this.props.notification.notificationType}
		>
			<Translation ns={'notifications'}>
				{(t) => <p>{t(`${this.props.notification.currentMessage}`)}</p>}
			</Translation>
		</NotificationBar>;
	}

	render() {
		if (this.props.fetching) {
			return (<div className={"page"}>{this.renderNotice()}<LoadOverlay/></div>);
		}

		const AdWordsPage = Manager(AdWords);
		const ReportsPage = Manager(Reports);
		const InvoicesPage = Admin(Invoices);

		return (
			<div className="page">
				<Header
					currentPath={this.props.router.location.pathname}
					onLogout={() => this.props.logout()}
					userName={this.props.auth ? this.props.auth.name : 'Guest'}
				/>
				<div className='region content'>
					{this.renderNotice()}
					<Switch>
						<Route
							exact
							path="/login"
							render={props => <Login {...props}/>}
						/>

						<Route exact path="/" component={User(Home)}/>

						<Route exact path="/adwords/list" component={AdWordsPage}/>
						<Route exact path="/adwords/create" render={(props) => <AdWordsPage {...props} create={true}/>}/>

						<Route exact path="/reports/list" component={ReportsPage}/>
						<Route exact path="/reports/create" render={(props) => <ReportsPage {...props} create={true}/>}/>

						<Route exact path="/companies" component={Admin(Companies)}/>

						<Route exact path="/invoices" component={InvoicesPage}/>
						<Route exact path="/invoices/create" render={(props)=><InvoicesPage {...props} create={true}/>}/>
						<Route path="/invoices/create/:id" render={(props)=><InvoicesPage {...props} create={true} companyId={props.match.params.id}/>}/>

						<Route component={NotFound}/>
					</Switch>
				</div>
				<Footer/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		auth: state.auth.data,
		error: state.auth.error,
		fetching: state.auth.fetching,
		router: state.router,
		notification: state.notification,
		incomingNotification: state.notification.working
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUserData: () => dispatch(AuthActions.userData()),
		loginWithToken: (token) => dispatch(AuthActions.loginRequestWithToken(token)),
		logout: () => dispatch(AuthActions.logoutRequest()),
		refreshToken: () => dispatch(AuthActions.refreshToken()),
		setNotification: (message, type) => dispatch(NotificationActions.setNotification(message, type))
	};
};

export default (module.hot) ? hot(module)(connect(mapStateToProps, mapDispatchToProps)(App)) : connect(mapStateToProps, mapDispatchToProps)(App);