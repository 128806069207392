import { call, put, select } from 'redux-saga/effects';
import CompanyActions, { CompanySelectors } from '../reducers/CompanyRedux';
import { UtilitySelectors } from '../reducers/UtilityRedux';
import AdWordsActions, { AdWordsSelectors } from '../reducers/AdWordsRedux';

function * companyListRequest(api) {
	const response = yield call(api.listCompanies);

	if (!response.ok) {
		return yield put(CompanyActions.companyFailure(response.data.error));
	}

	response.data.map(item => {
		item.default_vat_name = item.vat_type.name;
		delete item.vat_type;
	});

	yield put(CompanyActions.companyListSuccess(response.data));
}

function * companyListSuccess({list}) {
}

function * companyFailure({error}) {
}

function * companySaveRequest(api, {item}) {
	const response = yield call(api.saveCompany, item);

	if (!response.ok) {
		return yield put(CompanyActions.companyFailure(response.data.error));
	}

	yield put(CompanyActions.companySaveSuccess(response.data[0]));
}

function * companySaveSuccess({item}) {
	let list = yield select(CompanySelectors.list);

	item.default_vat_name = item.vat_type.name;
	delete item.vat_type;

	if (list) {
		let exists = false;
		list = list.map(value => {
			if (value.id === item.id) {
				exists = true;
				return item;
			}
			return value;
		});
		if (!exists) {
			list = [...list, item];
		}

		yield put(CompanyActions.companyListSuccess(list));
	} else {
		yield put(CompanyActions.companyListSuccess([item]));
	}
}

function * companyChangeVatRequest(api, {id, vatId}) {
	const response = yield call(api.changeVat, {id, vatId});

	if (!response.ok) {
		return yield put(CompanyActions.companyFailure(response.data.error));
	}

	const companies = [...yield select(CompanySelectors.list)];
	const vatTypes = yield select(UtilitySelectors.vatTypes);
	const companyIndex = companies.findIndex(company => company.id === id);
	const vatItem = vatTypes.find(item => item.id === vatId);

	if (companyIndex > -1 && vatItem) {
		companies[companyIndex] = {
			...companies[companyIndex],
			default_vat_type: vatItem.id,
			default_vat_name: vatItem.name
		};
		return yield put(CompanyActions.companyListSuccess(companies));
	}

	yield put(CompanyActions.companyFailure('invalidCompanyOrVat'));
}

function * companyAssignAccountsRequest(api, {id, accountIds}) {
	const response = yield call(api.assignAccounts, {id, accountIds});

	if (!response.ok) {
		return yield put(CompanyActions.companyFailure(response.data.error));
	}

	const companies = [...yield select(CompanySelectors.list)];
	const accounts = [...yield select(AdWordsSelectors.list)];
	const companyIndex = companies.findIndex(company => company.id === id);
	const selectedAccounts = accounts.reduce((acc, account) => {
		if(accountIds.findIndex(accountId => accountId === account.id) > -1) {
			acc.push({
				id: account.id,
				name: account.name
			})
		}
		return acc;
	}, []);

	if (companyIndex > -1) {
		if (selectedAccounts.length) {
			// Remove recently assigned accounts from other companies
			companies.map((company, key) => {
				companies[key] = {
					...companies[key],
					accounts: company.accounts.filter(account => selectedAccounts.findIndex(selAcc => selAcc.id === account.id) === -1)
				}
			});
		}

		// Cascade ownership changes into the account store
		accounts.map((account, key) => {
			if(selectedAccounts.findIndex(selAcc => selAcc.id === account.id) > -1) {
				accounts[key] = {
					...account,
					owner: {
						id: companies[companyIndex].id,
						name: companies[companyIndex].name
					}
				}
			} else if(account.owner && account.owner.id === companies[companyIndex].id) {
				accounts[key] = {
					...account,
					owner: null
				}
			}
		});

		// Add the recently assigned accounts to their new owner
		companies[companyIndex] = {
			...companies[companyIndex],
			accounts: selectedAccounts
		};

		yield put(AdWordsActions.adWordsListSuccess(accounts));
		return yield put(CompanyActions.companyListSuccess(companies));
	}

	yield put(CompanyActions.companyFailure('invalidCompany'));
}

function * companyDeleteRequest(api, {ids}) {
	const response = yield call(api.deleteCompany, ids);

	if (!response.ok) {
		return yield put(CompanyActions.companyFailure(response.data.error));
	}
	const list = yield select(CompanySelectors.list);
	yield put(CompanyActions.companyDeleteSuccess(list.filter(item => ids.indexOf(item.id) === -1)));
}

function * companyDeleteSuccess({list}) {
}

export {
	companyListRequest,
	companyListSuccess,
	companyFailure,
	companySaveRequest,
	companySaveSuccess,
	companyChangeVatRequest,
	companyAssignAccountsRequest,
	companyDeleteRequest,
	companyDeleteSuccess,
};