import { call, put, select } from 'redux-saga/effects';
import AuthActions, { AuthSelectors } from '../reducers/AuthRedux';
import { response_routine_check } from '../helpers/XhrHelper';
import { push } from "connected-react-router";
import LocalStorageService from '../services/LocalStorage';

const localStorageService = LocalStorageService.getService();

function * loginRequest(api, {email, password}) {
	const response = yield call(api.login, {email, password});

	const responseIsValid = response_routine_check(response);

	if (responseIsValid !== true) {
		return yield put(AuthActions.loginFailure({...response.data, origin: 'loginRequest'}));
	}

	yield put(AuthActions.loginSuccess(response.data));
}

function * loginRequestWithToken(api, {token}) {
	api.setAuthorization(token);
	yield put(AuthActions.refreshToken())
}

function * refreshToken(api) {
	const response = yield call(api.refresh);

	if (response.ok) {
		return yield put(AuthActions.loginSuccess(response.data));
	}

	api.clearAuthorization();
	localStorageService.clearToken();
	yield put(AuthActions.loginFailure({...response.data, origin: 'refreshToken'}));
}

function * loginSuccess(api, {data}) {
	if (data.auth_token) {
		api.setAuthorization(data.auth_token);

		localStorageService.setToken(data);
	}

	const hasUserData = yield select(AuthSelectors.hasUserData);
	if (!hasUserData) {
		yield put(AuthActions.userData());
	}
}

function * userData(api) {
	const response = yield call(api.userData);

	if (response.ok) {
		return yield put(AuthActions.loginSuccess(response.data));
	}

	return yield put(AuthActions.loginFailure({...response.data, origin: 'userData'}));
}

function * loginFailure({error}) {
	if (error.origin !== 'loginRequest') {
		yield put(push('/login'))
	}
}

function * logoutRequest(api) {
	if (localStorageService.isTokenValid()) {
		const response = yield call(api.logout);
	}

	yield put(AuthActions.logoutSuccess());
}

function * logoutSuccess(api) {
	api.clearAuthorization();
	localStorageService.clearToken();
	yield put(push('/login'))
}

export {
	loginRequest,
	loginRequestWithToken,
	refreshToken,
	loginSuccess,
	userData,
	loginFailure,
	logoutRequest,
	logoutSuccess,
};