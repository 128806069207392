/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */
import 'react-hot-loader/patch';

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import reducer from './reducers';
import App from './containers/App';
import { ConnectedRouter } from 'connected-react-router';
import './i18n';
import LoadOverlay from './components/LoadOverlay';

const { store, history } = reducer();

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Suspense
        fallback={
          <LoadOverlay/>
        }>
        <App/>
      </Suspense>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);