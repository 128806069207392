import React from 'react';

const Footer = () => {
  return (
    <div className={'region footer'}>
      <p>© {new Date().getFullYear()} SRG Group</p>
    </div>
  )
};

export default Footer;