import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LocalStorageService from './LocalStorage';

const localStorageService = LocalStorageService.getService();

export const UserContentRoles = ['user', 'manager', 'admin'];
export const ManagerContentRoles = ['manager', 'admin'];
export const AdminContentRoles = ['admin'];

/**
 * HOC for handling role based access to components
 *
 * @param allowedRoles
 * @returns {function(*)}
 * @constructor
 */
const Authorization = (allowedRoles) => (WrappedComponent) => {
	class WithAuthorization extends Component {
		static propTypes = {
			auth: PropTypes.object,
		};

		authorize() {
			const auth = this.props.auth;
			return auth && Array.isArray(auth.roles) && allowedRoles.some(r => auth.roles.indexOf(r) >= 0);
		}

		render() {
			if (!this.authorize()) {
				return null;
			}
			const {auth, dispatch, ...propsToPass} = this.props;
			return <WrappedComponent {...propsToPass} />;
		}
	}

	const mapStateToProps = (state) => {
		return {
			auth: state.auth.data,
		};
	};

	return connect(mapStateToProps, null)(WithAuthorization);
};

export const User = Authorization(UserContentRoles);
export const Manager = Authorization(ManagerContentRoles);
export const Admin = Authorization(AdminContentRoles);

class AuthWrapperComponent extends Component {
	static propTypes = {
		auth: PropTypes.object,
		allowedRoles: PropTypes.array,
	};

	authorize() {
		const auth = this.props.auth;
		return auth && Array.isArray(auth.roles) && this.props.allowedRoles.some(r => auth.roles.indexOf(r) >= 0);
	}

	render() {
		if (!this.authorize()) {
			return null;
		}

		return this.props.children;
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth.data,
	};
};

export const AuthWrapper = connect(mapStateToProps, null)(AuthWrapperComponent);
export default Authorization;