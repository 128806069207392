import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DropDownItem extends Component {
  static propTypes = {
    active: PropTypes.bool
  };

  constructor (props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  open = () => this.setState({ open: true });
  close = () => this.setState({ open: false });

  render () {
    return (
      <li
        className={`has-submenu${this.state.open ? ' open' : ''}${this.props.active ? ' active' : ''}`}
        onMouseEnter={this.open}
        onMouseLeave={this.close}>
        {this.props.children}
      </li>
    );
  }
}

export default DropDownItem;