import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from '../i18n';
import { withTranslation } from 'react-i18next';
import DropDownItem from '../components/DropDownItem';
import { AuthWrapper, UserContentRoles, ManagerContentRoles, AdminContentRoles } from '../services/Authorization';
import { Link } from 'react-router-dom';

class Header extends Component {
	static propTypes = {
		currentPath: PropTypes.string,
		onLogout: PropTypes.func,
		userName: PropTypes.string,
	};

	isActive(path) {
		return path === this.props.currentPath ? 'active' : '';
	}

	isDDActive(paths) {
		return paths.indexOf(this.props.currentPath) > -1;
	}

	changeLanguage(lang) {
		i18n.changeLanguage(lang);
	}

	render() {
		const {t} = this.props;
		return (
			<div className='region header' ref={r => this.headerDiv = r}>
				<div className='logo-container'>
					<img className='logo-image'
							 src={'/images/srg-logo.png'}
							 alt={t('common.appName')}
					/>
				</div>
				<nav className='main-navigation'>
					<ul className='navigation'>
						<AuthWrapper allowedRoles={UserContentRoles}>
							<li className={this.isActive('/')}>
								<Link className='menu-link' to='/'>{t('pages.home')}</Link>
							</li>
							<DropDownItem active={this.isDDActive(['/reports/list', '/reports/create'])}>
								<Link className='menu-link' to='/reports/list'>{t('pages.reports')}</Link>
								<div className='submenu'>
									<Link className='menu-link submenu-link' to={'/reports/list'}>{t('pages.list')}</Link>
									<Link className='menu-link submenu-link' to={'/reports/create'}>{t('pages.create')}</Link>
								</div>
							</DropDownItem>
						</AuthWrapper>
						<AuthWrapper allowedRoles={ManagerContentRoles}>
							<DropDownItem active={this.isDDActive(['/adwords/list', '/adwords/create', '/adwords/assign'])}>
								<Link className='menu-link' to='/adwords/list'>{t('pages.adwords')}</Link>
								<div className='submenu'>
									<Link className='menu-link submenu-link' to={'/adwords/list'}>{t('pages.list')}</Link>
									<Link className='menu-link submenu-link' to={'/adwords/create'}>{t('pages.create')}</Link>
									<Link className='menu-link submenu-link' to={'/adwords/assign'}>{t('pages.assign')}</Link>
								</div>
							</DropDownItem>
						</AuthWrapper>
						<AuthWrapper allowedRoles={AdminContentRoles}>
							<DropDownItem active={this.isDDActive(['/companies', '/invoices', '/invoices/item-templates'])}>
								<Link className='menu-link' to='/invoices'>{t('pages.accounting')}</Link>
								<div className='submenu'>
									<Link className='menu-link submenu-link' to='/companies'>{t('pages.companies')}</Link>
									<Link className='menu-link submenu-link' to='/invoices'>{t('pages.invoices')}</Link>
									<Link className='menu-link submenu-link' to='/invoices/item-templates'>{t('pages.invoiceItemTemplates')}</Link>
								</div>
							</DropDownItem>
							<DropDownItem active={this.isDDActive(['/users', '/roles', '/permissions'])}>
								<Link className='menu-link' to='#'>{t('pages.admin')}</Link>
								<div className='submenu'>
									<Link className='menu-link submenu-link' to={'/users'}>{t('pages.users')}</Link>
									<Link className='menu-link submenu-link' to='/roles'>{t('pages.roles')}</Link>
									<Link className='menu-link submenu-link' to='/permissions'>{t('pages.permissions')}</Link>
								</div>
							</DropDownItem>
						</AuthWrapper>
					</ul>
				</nav>
				<AuthWrapper allowedRoles={UserContentRoles}>
					<nav className='account-navigation'>
						<ul className='navigation'>
							<li>
								{t('common.welcome')}, {this.props.userName}
							</li>
							<li className='language-selector'>
								<Link className={'menu-link' + (i18n.language === 'de' ? ' active' : '')} to={'#'} onClick={() => this.changeLanguage('de')}>{t('languages.de')}</Link>
								<Link className={'menu-link' + (i18n.language === 'en' ? ' active' : '')} to={'#'} onClick={() => this.changeLanguage('en')}>{t('languages.en')}</Link>
								<Link className={'menu-link' + (i18n.language === 'hu' ? ' active' : '')} to={'#'} onClick={() => this.changeLanguage('hu')}>{t('languages.hu')}</Link>
							</li>
							<DropDownItem>
								<Link className='menu-link' to='/account/dashboard'>{t('pages.account')}</Link>
								<div className='submenu'>
									<Link className='menu-link submenu-link' to={'/users/dashboard'}>{t('pages.overview')}</Link>
									<Link className='menu-link submenu-link' to={'/users/change_password'}>{t('pages.changePassword')}</Link>
									<Link className='menu-link submenu-link' to={'#'} onClick={this.props.onLogout}>{t('pages.logout')}</Link>
								</div>
							</DropDownItem>
						</ul>
					</nav>
				</AuthWrapper>
			</div>
		);
	}
}

export default withTranslation()(Header);