import React from 'react';
import LoadIcon from './LoadIcon';

const LoadOverlay = function () {
  return (
    <div className="load-overlay-container">
      <div className={'load-overlay'}/>
      <LoadIcon/>
    </div>
  );
};

export default LoadOverlay;