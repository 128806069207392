import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import configureStore from './CreateStore';
import rootSaga from '../sagas/';
import { createBrowserHistory } from 'history';

/* ------------- Assemble The Reducers ------------- */
export const reducers = (history) => combineReducers({
  auth: require('./AuthRedux').reducer,
  notification: require('./NotificationRedux').reducer,
  adWords: require('./AdWordsRedux').reducer,
  companies: require('./CompanyRedux').reducer,
  invoices: require('./InvoiceRedux').reducer,
  reports: require('./ReportRedux').reducer,
  utilities: require('./UtilityRedux').reducer,
  router: connectRouter(history),
});

export default () => {
  const history = createBrowserHistory();
  let finalReducers = reducers(history);

  let { store, sagasManager, sagaMiddleware } = configureStore(
    finalReducers,
    rootSaga,
    history,
  );

  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require('./').reducers;
      store.replaceReducer(nextRootReducer(history));

      const newYieldedSagas = require('../sagas').default;
      sagasManager.cancel();
      sagasManager.done.then(() => {
        sagasManager = sagaMiddleware.run(newYieldedSagas);
      });
    });
  }

  return {
    history,
    store,
  };
};